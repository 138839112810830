// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer{
    background-color: #333333 !important;
}

.footer-content{
    color: rgb(247, 247, 247);
}

.footer-links{
    font-size: 20px;
    font-weight: 400;
    display: flex;
}

.footer-links .links{
    text-shadow: 1px 1px 1px black;
}

.footer-links a{
    text-decoration: none;
    color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/footer.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":["footer{\n    background-color: #333333 !important;\n}\n\n.footer-content{\n    color: rgb(247, 247, 247);\n}\n\n.footer-links{\n    font-size: 20px;\n    font-weight: 400;\n    display: flex;\n}\n\n.footer-links .links{\n    text-shadow: 1px 1px 1px black;\n}\n\n.footer-links a{\n    text-decoration: none;\n    color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
