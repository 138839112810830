// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-img{
    width: 100%;
    height: 100%;
}

.cards{
    margin: 1rem !important;
    min-height: 400px;
}

.light-mode .card-body{
    color: #000 !important;
}

.dark-mode .card-body{
    color: #fff !important;
}

.light-mode .card{
    background-color: #fff;
}
.dark-mode .card{
    background-color: #2e2e2e !important;
}

.card-title{
    font-size: 16px;
}

.card-text{
    font-size: 12px;
}

.row{
    margin: 0;
}

.card{
    border-radius: 0 !important;
}
.card:hover{
    background-color: #b4b4b4 !important;
}
.card:hover .card-body{
    color: #fff !important;
    cursor: pointer;
}

.card-body span{
    font-size: 11px;
}

.tr-border-radius{
    border-top-right-radius: 60px !important;
}

.card a{
    text-decoration: none;
}

.max-card{
    max-height: 275px !important;
}

.paginator_active{
    background: radial-gradient(#141414, #919191) !important;
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/card.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,oCAAoC;AACxC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,2BAA2B;AAC/B;AACA;IACI,oCAAoC;AACxC;AACA;IACI,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,wDAAwD;AAC5D","sourcesContent":[".card-img{\n    width: 100%;\n    height: 100%;\n}\n\n.cards{\n    margin: 1rem !important;\n    min-height: 400px;\n}\n\n.light-mode .card-body{\n    color: #000 !important;\n}\n\n.dark-mode .card-body{\n    color: #fff !important;\n}\n\n.light-mode .card{\n    background-color: #fff;\n}\n.dark-mode .card{\n    background-color: #2e2e2e !important;\n}\n\n.card-title{\n    font-size: 16px;\n}\n\n.card-text{\n    font-size: 12px;\n}\n\n.row{\n    margin: 0;\n}\n\n.card{\n    border-radius: 0 !important;\n}\n.card:hover{\n    background-color: #b4b4b4 !important;\n}\n.card:hover .card-body{\n    color: #fff !important;\n    cursor: pointer;\n}\n\n.card-body span{\n    font-size: 11px;\n}\n\n.tr-border-radius{\n    border-top-right-radius: 60px !important;\n}\n\n.card a{\n    text-decoration: none;\n}\n\n.max-card{\n    max-height: 275px !important;\n}\n\n.paginator_active{\n    background: radial-gradient(#141414, #919191) !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
