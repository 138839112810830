// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone {
  border: 1px solid #202124;
  border-width: 50px 7px;
  border-radius: 40px;
  margin: 10px auto;
  overflow: hidden;
  transition: all 0.5s ease;
}

.phone iframe {
  border: 0;
  width: 100%;
  height: 100%;
}
/*Different Perspectives*/

.phone.view {
  transform: rotateX(45deg) rotateY(0deg) rotateZ(-40deg);
  box-shadow: -3px 3px 0 #BBB, -6px 6px 0 #BBB, -14px 10px 20px #000;
  width: 320px;
  height: 560px;
}

@media (max-width:900px) {
  #wrapper {
    transform: scale(0.8, 0.8);
  }
}

@media (max-width:700px) {
  #wrapper {
    transform: scale(0.6, 0.6);
  }
}

@media (max-width:500px) {
  #wrapper {
    transform: scale(0.5, 0.5);
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/mobileViewer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT,WAAW;EACX,YAAY;AACd;AACA,yBAAyB;;AAEzB;EACE,uDAAuD;EACvD,kEAAkE;EAClE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".phone {\n  border: 1px solid #202124;\n  border-width: 50px 7px;\n  border-radius: 40px;\n  margin: 10px auto;\n  overflow: hidden;\n  transition: all 0.5s ease;\n}\n\n.phone iframe {\n  border: 0;\n  width: 100%;\n  height: 100%;\n}\n/*Different Perspectives*/\n\n.phone.view {\n  transform: rotateX(45deg) rotateY(0deg) rotateZ(-40deg);\n  box-shadow: -3px 3px 0 #BBB, -6px 6px 0 #BBB, -14px 10px 20px #000;\n  width: 320px;\n  height: 560px;\n}\n\n@media (max-width:900px) {\n  #wrapper {\n    transform: scale(0.8, 0.8);\n  }\n}\n\n@media (max-width:700px) {\n  #wrapper {\n    transform: scale(0.6, 0.6);\n  }\n}\n\n@media (max-width:500px) {\n  #wrapper {\n    transform: scale(0.5, 0.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
