// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade {
    opacity: 0;
    transition: opacity 0.5s;
}

.fade.show {
    opacity: 1;
}

.fade.hide {
    opacity: 0;
}

.light-mode h4{
    color: #000;
}

.dark-mode h4{
    color: #fff;
}
  `, "",{"version":3,"sources":["webpack://./src/assets/css/projects.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":[".fade {\n    opacity: 0;\n    transition: opacity 0.5s;\n}\n\n.fade.show {\n    opacity: 1;\n}\n\n.fade.hide {\n    opacity: 0;\n}\n\n.light-mode h4{\n    color: #000;\n}\n\n.dark-mode h4{\n    color: #fff;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
